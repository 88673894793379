import dynamic from "next/dynamic";
const SideList = dynamic(() => import("./grid-types/side-list"));
const Columns = dynamic(() => import("./grid-types/columns"));
const TextGrid = dynamic(() => import("./grid-types/text"));

export default function Grid({
  fields,
  sys,
  includes,
  plain,
  addItem = null,
  removeItem = null,
  ...rest
}) {
  let type = null;
  switch (fields.type) {
    case "Side List":
      type = (
        <SideList
          fields={fields}
          sys={sys}
          includes={includes}
          plain={plain}
          addItem={addItem}
          removeItem={removeItem}
          {...rest}
        />
      );
      break;
    case "2x2":
      type = (
        <Columns
          columns={2}
          fields={fields}
          sys={sys}
          includes={includes}
          plain={plain}
          addItem={addItem}
          removeItem={removeItem}
        />
      );
      break;
    case "3 Column":
      type = (
        <Columns
          columns={3}
          fields={fields}
          sys={sys}
          includes={includes}
          plain={plain}
          addItem={addItem}
          removeItem={removeItem}
          {...rest}
        />
      );
      break;
    case "Text Grid":
      type = (
        <TextGrid
          fields={fields}
          sys={sys}
          includes={includes}
          plain={plain}
          addItem={addItem}
          removeItem={removeItem}
        />
      );
      break;
    default:
      type = null;
  }

  return type;
}
